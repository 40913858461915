export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyB-pGl2oXjcV7Hkb3f806ikKzDJxSSPhDw",
        authDomain: "cwl-2a15987cf385-qa.firebaseapp.com",
    },
    region: 'europe-west1',
    brain_base_url: 'https://helaba.api-test.deloitte.camp',
    default_app_lang: "de-DE",
    aoh_url: 'https://aoh-qa.helaba.cwl.camp/',
    store_url: 'https://store-qa.helaba.cwl.camp/',
    api_base_url: 'https://helaba.api-test.deloitte.camp',
    authParams: ['oidc.campfire'],
    signInType: 'signInWithPopup',
    hasEnterpriseAssetSearch: false,
    showBarrierQuestions: false,
    asset_redirect_uri: {},
    upload: {
        allowUnclassifiedFilesUpload: false,
        confidentialMetadata: "MSIP_Label_814c5618-1c13-43d0-be59-11a160d74407_Name=C4,MSIP_Label_55da0417-30af-480a-8d77-89cd6cbc1948_Name=C4 – streng vertraulich (ohne Kennzeichnung)",
        existingMetadata: "MSIP_Label_ace05a39-0b2c-4225-8b72-da65bfad9769_Name=C1,MSIP_Label_31472b75-c5ad-4aeb-acdd-442c1f2868d6_Name=C2,MSIP_Label_249bdf21-2fb4-4be9-9322-664ae40582cd_Name=C2 – konzernintern (ohne Kennzeichnung),MSIP_Label_d8ebb1f5-dae0-4c3f-8073-8b58a47fb9d3_Name=C3,MSIP_Label_90718f9c-7e83-495a-95f5-588c1ccf1447_Name=C3 – vertraulich (ohne Kennzeichnung),MSIP_Label_814c5618-1c13-43d0-be59-11a160d74407_Name=C4,MSIP_Label_55da0417-30af-480a-8d77-89cd6cbc1948_Name=C4 – streng vertraulich (ohne Kennzeichnung)"
    },
    showInvitatio: false,
    hidePackagesAndPlans: true,
    business_hideServiceOfferings: true,
    business_hideIsIndustryAligned: true,
    business_hideIndustries: true,
    business_hideAlliances: true,
    business_hideRevenueForm: true,
    business_hideGeographyQuestion: true,
    business_hideBusinessLineAlignment: true,
    technical_hideContainerizedQuestion: true,
    technical_hideInformOnboardingTeamButton: true,
    hideBeforeYouStartPopup: true,
    business_hideOfficeAddress: true,
    entitlements: {
        aoh_hide_add_users_button: true,
        aoh_hide_deploy_button: true
    },
    hideBarrierSuccessPopup: true,
    postBarrierQuestionsRoute: ['technical','onboarding'],
    onboarding: {
        displayVideo: false,
        video_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4',
        development: {
            frontend_url: 'https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf',
            backend_url: 'https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse',
            business_support_email: 'mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace',
        },
    },
    barrierQuestionsAbort: {
        mailAddress: 'Support-Helaba-AI@helaba.de',
    },
    support: {
        ccEmail: "Support-Helaba-AI@helaba.de",
        contact: "mailto:campfire@deloitte.de?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
    },
    onboarding_options: {
        "gen_ai": {
            api_url: "https://helaba-geniusai.connect-test.api.deloitte.ai",
            app_url: "https://geniusai-test.helaba.cwl.camp/workflow-selector"
        }
    }
};